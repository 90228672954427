import {
  type GroupNoteDefinition,
  type HealthcareService,
  type NoteDefinition,
  type NoteRule,
  NoteRuleCriteriaType,
  type NoteRuleOutcomeType,
  type Reference
} from "@remhealth/apollo";
import type { Labeling } from "@remhealth/core";
import { Text } from "~/text";
import { type BuiltInNoteRule, isBuiltInRule } from "./builtInRules";

export function getNoteRuleDisplay(ruleType: NoteRuleCriteriaType, labels: Labeling): string {
  switch (ruleType) {
    case NoteRuleCriteriaType.LocationSelected: return labels.Location;
    case NoteRuleCriteriaType.MissingDiagnosis: return "Missing Information";
    case NoteRuleCriteriaType.ProgramSelected: return "Program Selection";
    case NoteRuleCriteriaType.ServiceDate: return Text.SessionDate(labels);
    case NoteRuleCriteriaType.SessionDuration: return `${labels.Session} Duration`;
    case NoteRuleCriteriaType.ShowStatus: return Text.SessionStatus(labels);
    case NoteRuleCriteriaType.SpanMidnight: return "Span Midnight";
    case NoteRuleCriteriaType.AppointmentRequired: return "No Appointment, No Note";
    case NoteRuleCriteriaType.SessionOverlap: return `Prevent ${labels.Session} Time Overlap`;
    case NoteRuleCriteriaType.Authorization: return "Authorizations";
    case NoteRuleCriteriaType.SessionDurationIncrement: return "Session Increments";
    case NoteRuleCriteriaType.ProgramLocation: return `Program ${labels.Location}`;
  }
}

export function isNoteRuleBasedOnNoteType(rule: NoteRule | BuiltInNoteRule): boolean {
  if (isBuiltInRule(rule)) {
    return rule.basedOnNoteType === true;
  }

  switch (rule.criteria.type) {
    case NoteRuleCriteriaType.MissingDiagnosis: return true;
    default: return false;
  }
}

export function getAllowedServicesFromRule(rule: NoteRule | BuiltInNoteRule, noteDefinition: NoteDefinition | GroupNoteDefinition, ehrServices: HealthcareService[]) {
  let allowedServices: Reference<HealthcareService>[] = [];
  if (rule.outcome.type === "ChangeService") {
    allowedServices = rule.outcome.services;
    if (ehrServices && ehrServices.length > 0) {
      allowedServices = allowedServices.filter(s => ehrServices.some(service => service.id === s.id));
    }
    if (noteDefinition && noteDefinition.resourceType === "NoteDefinition") {
      allowedServices = allowedServices.filter(s => noteDefinition.services.some(service => service.id === s.id));
    }
  }
  return allowedServices;
}

export function getValidationsAppliedProperties(rule: NoteRule, labels: Labeling, isSessionInfo?: boolean) {
  return {
    name: getNoteRuleDisplay(rule.criteria.type, labels),
    action: getValidationsAppliedAction(rule.outcome.type, isSessionInfo),
  };
}

function getValidationsAppliedAction(outcome: NoteRuleOutcomeType, isSessionInfo?: boolean) {
  switch (outcome) {
    case "Blocker": {
      if (isSessionInfo) {
        return Text.PreventStartNote;
      }
      return Text.PreventSignNote;
    }
    case "ChangeService": return Text.ChangeServiceTypePrompt;
    case "Warn": return Text.WarnSignNote;
  }
}
