import { CarePlanActivity, GoalElementActivity, groupBy } from "@remhealth/apollo";
import { Text } from "~/text";

export interface GoalsObjectivesSectionProps {
  carePlanActivities: CarePlanActivity[];
  showGoalTrackingInfo?: boolean;
  ordered?: boolean;
}

export function GoalsObjectivesSection(props: GoalsObjectivesSectionProps) {
  const { carePlanActivities, showGoalTrackingInfo, ordered = true } = props;

  return ordered ? <ol>{renderCarePlanActivities()}</ol> : <ul>{renderCarePlanActivities()}</ul>;

  function renderCarePlanActivities() {
    return carePlanActivities.map(activity => {
      const groups = Array.from(groupBy(activity.activity, g => g.addresses?.display ?? ""));
      if (groups.some(g => g[0])) {
        return groups.map(g => renderGroupedGoals(g[0], g[1]));
      }
      return activity.activity.map(renderGoal);
    });
  }

  function renderGroupedGoals(title: string, goals: GoalElementActivity[]) {
    return (
      <li key={title}>
        {title}
        <ul>{goals.map(renderGoal)}</ul>
      </li>
    );
  }

  function renderGoal(goal: GoalElementActivity, goalIndex: number) {
    return (
      <li key={goalIndex}>
        {goal.lifecycleStatusCode && <>[{goal.lifecycleStatusCode.display}] - </>}
        {goal.detail ?? goal.code?.text}
        {renderGoalElement(goal)}
        {!showGoalTrackingInfo && (goal.comments?.value || goal.comments?.plainText) && (
          <dl className="comments">
            <dt className="label">{Text.Comment}:</dt>
            {goal.comments?.value?.trim()
              ? <dd dangerouslySetInnerHTML={{ __html: goal.comments.value ?? "" }} className="content" />
              : <dd className="content">{goal.comments.plainText}</dd>}
          </dl>
        )}
      </li>
    );
  }

  function renderGoalElement(element: GoalElementActivity) {
    if (element.children.length > 0) {
      return (
        <ul>
          {element.children.map((child, index) => {
            return (
              <li key={index}>
                {child.lifecycleStatusCode && <>[{child.lifecycleStatusCode.display}] - </>}
                {child.detail ?? child.code?.text}
                {showGoalTrackingInfo && renderGoalElementFields(child)}
                {renderGoalElement(child)}
              </li>
            );
          })}
        </ul>
      );
    }
    return <br />;
  }

  function renderGoalElementFields(activity: GoalElementActivity) {
    return (
      <table>
        <tbody>
          <tr>
            <th>Level of Assistance</th>
            <th>Number of Prompts</th>
            <th>Achieved</th>
            <th>Comments</th>
          </tr>
          <tr>
            <td>{activity.levelOfAssistance?.text}</td>
            <td>{activity.numberOfPromptsCode?.display}</td>
            <td>{activity.achievementStatus === "Achieved" ? "Yes" : "No"}</td>
            <td>
              {activity.comments?.value?.trim()
                ? <div dangerouslySetInnerHTML={{ __html: activity.comments.value ?? "" }} className="content" />
                : <div className="content">{activity.comments?.plainText}</div>}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
